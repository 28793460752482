
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import CouponCenter from "@/views/Mine/AssetCenter/Coupon/MyCoupons.vue";

@Component({
  components: {
    CouponCenter,
  },
})
export default class ExternalCouponCenterPage extends Mixins(Mixin) {}
